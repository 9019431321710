import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={90}
		height={40}
		viewBox='0 0 90 40'
		fill='none'
		{...props}
	>
		<path
			fill='#297EFF'
			d='M75.339 10.709S80.427 7.705 85.659 0c1.358 7.22 3.829 11.436 3.829 11.436l-2.714-.097C77.664 35.083 46.071 32.95 46.071 32.95v-.146c15.555-1.114 26.457-9.303 32.32-22.096H75.34Z'
		/>
		<path
			fill='#297EFF'
			d='M81.707 32.74H71.053l-3.177-5.508 8.092-3.828 5.739 9.337ZM72.385 17.643c-1.738 2.533-3.576 4.634-5.45 6.378l-6.35-11.326h9.318l2.482 4.948Z'
		/>
		<path
			fill='#231F20'
			d='M76.741 24.663c-.256.4-1.022 1.522-2.374 2.908l-.004.002a25.594 25.594 0 0 1-4.27 3.503L68.9 29.01a29.616 29.616 0 0 0 .604-.255c.002 0 .005 0 .007-.003l.181-.077c.002 0 .004 0 .006-.003a33.816 33.816 0 0 0 1.1-.5 35.017 35.017 0 0 0 3.703-2.04l.001-.001a8.48 8.48 0 0 0 .155-.1c.09-.056.18-.114.27-.173l.03-.02.03-.02c.16-.105.32-.212.477-.32a25.781 25.781 0 0 0 .82-.58c.051-.036.101-.072.15-.11l.1-.074a9.193 9.193 0 0 0 .172-.127l.035.057Z'
			opacity={0.4}
		/>
		<path
			fill='#fff'
			d='M26.95 14.29H1.987L0 18.55h22.044l4.906-4.26ZM62.835 19.768l-.265.567h.005l-1.117 2.395c-.644 1.382-2.63 2.502-4.433 2.502h-9.842l-2.48 5.32h-7.37l4.541-9.739c.123-.264.502-.477.845-.477H55.2l.835-1.787H42.929l1.987-4.26h14.847l3.072 5.479ZM7.123 20.335h7.469L9.768 30.553h-7.43l4.785-10.216Z'
		/>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M27.5 16.43a8 8 0 0 1 5.435-2.13H42.4l-7.6 16.3h-7l1.056-2.1h-7.189L19.4 30.6h-7.2l15.3-14.17Zm6.387 2.07-3.018 6h-4.883l6.479-6h1.422Z'
			clipRule='evenodd'
		/>
		<path
			fill='#fff'
			d='M26.95 14.29H1.987L0 18.55h22.044l4.906-4.26ZM62.835 19.768l-.265.567h.005l-1.117 2.395c-.644 1.382-2.63 2.502-4.433 2.502h-9.842l-2.48 5.32h-7.37l4.541-9.739c.123-.264.502-.477.845-.477H55.2l.835-1.787H42.929l1.987-4.26h14.847l3.072 5.479ZM7.123 20.335h7.469L9.768 30.553h-7.43l4.785-10.216Z'
		/>
		<path
			fill='#fff'
			fillRule='evenodd'
			d='M27.5 16.43a8 8 0 0 1 5.435-2.13H42.4l-7.6 16.3h-7l1.056-2.1h-7.189L19.4 30.6h-7.2l15.3-14.17Zm6.387 2.07-3.018 6h-4.883l6.479-6h1.422Z'
			clipRule='evenodd'
		/>
	</svg>
);

export default SvgComponent;
